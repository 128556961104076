import { toast } from "@/Hooks/Functions";
import { JSONPostFileRequest } from "@/Hooks/JSONRequest";
import { cn } from "@/lib/utils";
import { Icon } from "@iconify/react";
import "@splidejs/react-splide/css";
import React, { useCallback, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";

const DropzoneSingle = ({
    type = "images",
    disk = "uploads",
    className = "",
    extraProps = null,
    acceptedFiles = { "image/png": [], "image/jpeg": [], "image/jpg": [], "image/gif": [] },
    onChange = () => { },
    placeholder = "Drag 'n' drop image here, or click to select file",
    ...rest
}) => {
    const UPLOAD_ICON = "mdi:cloud-upload";
    const LOADING_ICON = "svg-spinners:blocks-shuffle-3";
    const ICON_SIZE = 80;
    const [uploading, setUploading] = useState(false);

    const onDrop = useCallback(async (acceptedFiles) => {
        if (acceptedFiles?.length <= 0) {
            toast(`Max of ${maxFiles} files.`, 'error')
            return;
        }
        let formData = new FormData();
        formData.append("type", type);
        formData.append("disk", disk);
        formData.append("file", acceptedFiles[0]);
        if (extraProps) {
            for (const key in extraProps) {
                if (extraProps.hasOwnProperty(key)) {
                    formData.append(key, extraProps[key]);
                }
            }
        }
        JSONPostFileRequest({
            href: route("dropzone.single"),
            data: formData,
            onResult: (val) => onChange(val),
            onError: (val) => { console.log(val); toast("Oops! something went wrong.", "error") },
            onLoading: (val) => setUploading(val),
        });
    }, [type, disk, extraProps, onChange]);

    const dropzoneConfig = useMemo(() => ({
        accept: acceptedFiles,
        multiple: false,
        onDrop: onDrop,
    }), [acceptedFiles, onDrop]);

    const { getRootProps, getInputProps } = useDropzone(dropzoneConfig);

    return (
        <div
            className={cn("w-full flex flex-col justify-center items-center border-dashed my-2 border px-2", className)}
            {...rest}
        >
            <div
                {...getRootProps({ className: "dropzone" })}
                className="flex flex-col group justify-center items-center p-4"
            >
                {uploading ? (
                    <Icon icon={LOADING_ICON} width={ICON_SIZE} height={ICON_SIZE} />
                ) : (
                    <Icon icon={UPLOAD_ICON} className="group-hover:text-cyan-600 text-[3rem]" />
                )}

                <input {...getInputProps()} />
                <p className="group-hover:text-cyan-600 text-center text-sm w-full cursor-pointer">{placeholder}</p>
            </div>
        </div>
    );
}
export default React.memo(DropzoneSingle);
